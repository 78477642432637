import React, { useState } from "react"
import { Card, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { Notification } from 'auth-components-package';
import CompanyUsers from "./CompanyUsers";
import InviteUser from "./InviteUser";
import ContentManagement from "./ContentManagement";
import LinkManagement from "./LinkManagement";
export const Admin = () => {
    const [notificationHeading, setNotificationHeading] = useState<string>("");
    const [notificationMessage, setNotificationMessage] = useState<string>("");
    const [notificationType, setNotificationType] = useState<string>("");

    return (
        <>
            <Text className="page-heading">Administration</Text>
            <Card width="inherit" padding={"20px 15px 20px 15px"} boxShadow={"none"}>
                <Tabs color={"#2C7A7B"}>
                    <TabList color="#2C7A7B">
                        <Tab color="#2C7A7B">Company Users</Tab>
                        <Tab color="#2C7A7B">Invite Users</Tab>
                        <Tab color="#2C7A7B">Content Management</Tab>
                        <Tab color="#2C7A7B">Link Management</Tab>
                    </TabList>

                    <TabPanels color="#2C7A7B">
                        <TabPanel color="#2C7A7B">
                            <CompanyUsers setNotificationHeading={setNotificationHeading} setNotificationMessage={setNotificationMessage} setNotificationType={setNotificationType} />
                        </TabPanel>
                        <TabPanel color="#2C7A7B">
                            <InviteUser setNotificationHeading={setNotificationHeading} setNotificationMessage={setNotificationMessage} setNotificationType={setNotificationType} />
                        </TabPanel>
                        <TabPanel color="#2C7A7B">
                            <ContentManagement />
                        </TabPanel>
                        <TabPanel color="#2C7A7B">
                            <LinkManagement />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                {notificationMessage && <Notification
                    heading={notificationHeading}
                    message={notificationMessage}
                    type={notificationType}
                />}
            </Card>
        </>
    )
}