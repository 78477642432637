import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Text,
    Select,
    InputGroup,
    InputLeftElement,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Tag,
    TagLabel,
    TagCloseButton,
    Wrap,
    WrapItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Icon
} from '@chakra-ui/react'
import { FiEdit, FiCopy, FiUsers, FiEye } from 'react-icons/fi';
import { useState, useRef } from 'react';
import { Api, baseApiParams, WorkflowInviteRequest } from "my-api-client-package";
import { apiMedia } from '../../helpers/api'
import React from 'react';
import store from "../../store/store.js";
import { connect } from 'react-redux';
import { setLanguage, setGender } from '../../store/videoFormSlice';
import Swal from 'sweetalert2';
import { IoCreate } from 'react-icons/io5';
const VideoForm = (props: any) => {

    const [resolution, setResolution] = useState("1920x1080"); // Add state for resolution
    const [mode, setMode] = useState("video"); // Add state for mode
    const [videoName, setVideoName] = useState("Video: Finalisierung"); // Add state for video name
    const [isRunning, setIsRunning] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertHeading, setAlertHeading] = useState("");
    const [alertType, setAlertType] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [infoNotify, setInfoNotify] = useState(false);

    const [startDateTime, setStartDateTime] = useState(new Date().toISOString().slice(0, 16));

    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const mediaApi = apiMedia;

    const isCompletedRef = useRef(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [emailInput, setEmailInput] = useState("");
    const [emailList, setEmailList] = useState<string[]>([]);
    const [emailError, setEmailError] = useState("");
    const [showResults, setShowResults] = useState(false);
    const [successEmails, setSuccessEmails] = useState<string[]>([]);
    const [failedEmails, setFailedEmails] = useState<string[]>([]);
    const [shareLink, setShareLink] = useState("");
    const [copySuccess, setCopySuccess] = useState(false);

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            const email = emailInput.trim();
            if (email && validateEmail(email)) {
                if (!emailList.includes(email)) {
                    setEmailList([...emailList, email]);
                    setEmailInput("");
                    setEmailError("");
                } else {
                    setEmailError("Email already added");
                }
            } else {
                setEmailError("Please enter a valid email");
            }
        }
    };

    const handleRemoveEmail = (emailToRemove: string) => {
        setEmailList(emailList.filter(email => email !== emailToRemove));
    };

    const handlePreview = () => {
        setShowResults(true);
    };

    const handleVideo = async (e: React.MouseEvent<HTMLButtonElement>) => {

        const segments = props.editTasksSegments?.length > 0 ? props.editTasksSegments : props.TasksSegments

        let combinedSegments: any[] = [];
        console.log(segments)

        if (mode === "video") {

            const schnittSegments = segments.filter(
                (segment: any) =>
                    segment.ressources &&
                    segment.ressources[0] &&
                    segment.ressources[0].label === "Schnitt"
            );

            const audioSegments = segments.filter(
                (segment: any) =>
                    segment.ressources &&
                    segment.ressources[0] &&
                    segment.ressources[0].label === "Sprechertext"
            );
            let updatedAudioSegments: any[] = [];
            if (audioSegments.length > 0) {
                try {
                    // const media = (await api.media.getMedia(audioSegments[0]?.speakeraudios[0]?.id, baseApiParams())).data
                    updatedAudioSegments = audioSegments.map((segment: any) => {
                        return {
                            ...segment,
                            audioproperties: {
                                gender: props.gender,
                                language: props.language,
                                speakertext: segment.description,
                                segment_id: segment.id
                            }
                        };
                    });
                } catch (error) {
                    console.error("Fehler beim Laden der Audio-Properties:", error);
                    // Optional: Hier könnte man einen Fehler-State setzen oder dem Benutzer eine Nachricht anzeigen
                }
            }
            combinedSegments = [...schnittSegments, ...updatedAudioSegments];
        } else {
            const audioSegments = segments.filter(
                (segment: any) =>
                    segment.level === "maintask"
            );

            let updatedAudioSegments: any[] = [];
            if (audioSegments.length > 0) {
                try {
                    const media = (await api.media.getMedia(audioSegments[0]?.speakeraudios[0]?.id, baseApiParams())).data
                    updatedAudioSegments = audioSegments.map((segment: any) => {
                        return {
                            ...segment,
                            audioproperties: {
                                ...media.audioproperties,
                                speakertext: segment.speakertext,
                                segment_id: segment.id
                            }
                        };
                    });
                } catch (error) {
                    console.error("Fehler beim Laden der Audio-Properties:", error);
                    // Optional: Hier könnte man einen Fehler-State setzen oder dem Benutzer eine Nachricht anzeigen
                }
            }
            combinedSegments = updatedAudioSegments;
        }

        const transfer = {
            processing: {
                command: combinedSegments, // hier verwenden wir die kombinierten Segmente
                label: videoName,
                videomode: mode,
                server: process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') ||
                    process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN', // Server-URL oder Identifier einfügen
                mediaproperties: {
                    resolution: resolution,
                    workflowlibraryId: props.user.privateCollection?.id, // Library ID einfügen
                    videoName: videoName
                }
            }
        };
        const headers = baseApiParams()
        headers['headers']['server'] = process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') || process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN'
        headers['headers']['Content-Type'] = 'application/json'  // Add this line


        const thread_id = (await (await mediaApi.processvideo.segmentsCreate(transfer, headers)).json())['thread_id']
        setIsRunning(true);
        isCompletedRef.current = false;
        while (!isCompletedRef.current) {
            const state = (await (await apiMedia.checkStatus.checkStatusDetail(thread_id, headers)).json())['status'];

            if (state === 'Running') {
                await new Promise(resolve => setTimeout(resolve, 10000));
                console.log('running');
            } else if (state === 'Completed') {
                console.log('completed');
                isCompletedRef.current = true;
                setIsRunning(false);
            } else {

                console.log(`error: ${state}`);
                isCompletedRef.current = true;
                setIsRunning(false);
            }
        }
    }

    const handleCreate = () => {
        setIsCreating(true);
        const selectedWorkflowItem = JSON.parse(localStorage.getItem("selected_workflow_item") || "{}");
        const formattedDateTime = new Date(startDateTime).toISOString();
        const inviteRequest: WorkflowInviteRequest = {
            emails: emailList,
            expiresAt: formattedDateTime
        }
        api.workflow.inviteUsersToWorkflow(selectedWorkflowItem.id.id, inviteRequest, baseApiParams()).then((res) => {
            setSuccessEmails(res.data.success || []);
            setFailedEmails(res.data.failed || []);
            setShareLink(res.data.link || "");
            setShowResults(true);
        }).catch((error) => {
            console.error("Error inviting users:", error);
            Swal.fire({
                title: 'Error!',
                text: 'Failed to invite users. Please try again.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        }).finally(() => {
            setIsCreating(false);
        });
    }

    const handleCopyShareLink = () => {
        navigator.clipboard.writeText(shareLink);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    }

    const isMobile = store.getState().isMobile;
    const containerAnnotationRef = store.getState().refs.containerAnnotationRef;
    console.log("isCompletedRef", isCompletedRef.current);
    return (
        <Box as="form" bg="bg.surface" className="segments-form" boxShadow="sm" overflow="auto" borderRadius="lg" {...props} height={!isMobile ? containerAnnotationRef - 60 : "fit-content"}>
            <Stack spacing="4" padding="5px">

                <Flex direction="row" gap="4">
                    <FormControl>
                        <FormLabel className="input-label">Videoname</FormLabel>
                        <Input
                            value={videoName}
                            onChange={(e) => setVideoName(e.target.value)}
                            placeholder="Geben Sie einen Videonamen ein"
                            size="sm"
                            borderRadius="md"
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel className="input-label">Videoauflösung</FormLabel>
                        <Select
                            value={resolution}
                            onChange={(e) => setResolution(e.target.value)}
                            size="sm"
                            borderRadius="md"
                        >
                            <option value="1920x1080">1920x1080 (Full HD)</option>
                            <option value="1280x720">1280x720 (HD)</option>
                            <option value="960x540">960x540 (qHD)</option>
                        </Select>
                    </FormControl>
                </Flex>
                <Flex direction="column">
                    <FormControl>
                        <FormLabel className="input-label">Modus</FormLabel>
                        <Select
                            value={mode}
                            onChange={(e) => setMode(e.target.value)}
                            size="sm"
                            borderRadius="md"
                        >
                            <option value="presentation">Presentation</option>
                            <option value="video">Video</option>
                        </Select>
                    </FormControl>

                    <Divider my={3} />
                    <Text fontSize="lg" fontWeight="medium">
                        Audioproperties
                    </Text>

                    <Flex>
                        <FormControl>
                            <FormLabel className="input-label">Sprache</FormLabel>
                            <Select
                                width="145px"
                                value={props.language}
                                onChange={(e) => props.dispatch(setLanguage(e.target.value))}
                                size="sm"
                                borderRadius="md"
                            >
                                <option value="German">Deutsch</option>
                                <option value="English">Englisch</option>
                            </Select>
                        </FormControl>

                        <FormControl>
                            <FormLabel className="input-label">Stimme</FormLabel>
                            <Select
                                width="145px"
                                value={props.gender}
                                onChange={(e) => props.dispatch(setGender(e.target.value))}
                                size="sm"
                                borderRadius="md"
                            >
                                <option value="male">Männlich</option>
                                <option value="female">Weiblich</option>
                            </Select>
                        </FormControl>
                    </Flex>
                </Flex>
                <Divider m={0} />

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <Button
                        as="label"
                        borderColor="#4ba9aa"
                        variant="outline"
                        leftIcon={<FiEdit color="#4ba9aa" />}
                        isDisabled={isRunning}
                        cursor="pointer"
                        onClick={(e) => handleVideo(e)}
                        size="sm"
                    >
                        Create Video
                    </Button>
                </Stack>
                <Divider m={0} p={0} />
                <Stack direction="row" gap={4} alignItems="center">
                    <Flex gap={2} alignItems="center">
                        <Button
                            as="label"
                            borderColor="#4ba9aa"
                            isDisabled={isRunning}
                            variant="outline"
                            size="sm"
                            leftIcon={<FiCopy color="#4ba9aa" />}
                            onClick={onOpen}
                        >
                            Share With
                        </Button>

                        {emailList.length > 0 && (
                            <Popover trigger="hover" placement="top">
                                <PopoverTrigger>
                                    <Box cursor="pointer">
                                        <Icon as={FiUsers} color="#4ba9aa" boxSize={5} />
                                    </Box>
                                </PopoverTrigger>
                                <PopoverContent width="300px" borderColor="#4ba9aa">
                                    <PopoverBody p={3}>
                                        <Text fontSize="sm" fontWeight="medium" mb={2}>Shared with:</Text>
                                        <Wrap spacing={2}>
                                            {emailList.map((email) => (
                                                <WrapItem key={email}>
                                                    <Tag size="md" borderRadius="full" variant="solid" colorScheme="teal">
                                                        <TagLabel>{email}</TagLabel>
                                                        <TagCloseButton onClick={() => handleRemoveEmail(email)} />
                                                    </Tag>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        )}
                    </Flex>

                    <FormControl>
                        <InputGroup>
                            <Input
                                type="datetime-local"
                                value={startDateTime}
                                min={(() => {
                                    const now = new Date();
                                    const localNow = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
                                    return localNow.toISOString().slice(0, 16);
                                })()}
                                onChange={(e) => {
                                    const selectedDate = new Date(e.target.value);
                                    const now = new Date();
                                    const localSelectedDate = new Date(selectedDate.getTime() - (selectedDate.getTimezoneOffset() * 60000));

                                    if (localSelectedDate < now) {
                                        Swal.fire({
                                            title: 'Error!',
                                            text: 'Please select a future date and time.',
                                            icon: 'error',
                                            confirmButtonText: 'Ok'
                                        });
                                        const localNow = new Date(now.getTime() - (now.getTimezoneOffset() * 60000));
                                        setStartDateTime(localNow.toISOString().slice(0, 16));
                                    } else {
                                        setStartDateTime(e.target.value);
                                    }
                                }}
                                _hover={{ borderColor: "#4ba9aa" }}
                                _focus={{ borderColor: "#4ba9aa" }}
                                size="sm"
                                borderRadius="md"
                            />
                        </InputGroup>
                    </FormControl>
                </Stack>

                <Stack direction="row" gap={4} alignItems="center">
                    <Button
                        size="sm"
                        w={'full'}
                        borderColor="#4ba9aa"
                        variant="outline"
                        leftIcon={<IoCreate color="#4ba9aa" />}
                        onClick={handleCreate}
                        isLoading={isCreating}
                        loadingText="Creating..."
                    >
                        Create
                    </Button>
                    {shareLink && <Box cursor="pointer" onClick={handlePreview}>
                        <Icon as={FiEye} color="#4ba9aa" boxSize={5} />
                    </Box>}
                </Stack>

                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add Email Addresses</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Stack spacing={4}>
                                <FormControl isInvalid={!!emailError}>
                                    <FormLabel>Enter Email Address</FormLabel>
                                    <Input
                                        value={emailInput}
                                        onChange={(e) => {
                                            setEmailInput(e.target.value);
                                            setEmailError("");
                                        }}
                                        onKeyDown={handleEmailInputKeyDown}
                                        placeholder="Enter email and press Enter or comma"
                                        borderColor="#4ba9aa"
                                        _hover={{ borderColor: "#4ba9aa" }}
                                        _focus={{ borderColor: "#4ba9aa" }}
                                    />
                                    {emailError && (
                                        <Text color="red.500" fontSize="sm" mt={1}>
                                            {emailError}
                                        </Text>
                                    )}
                                </FormControl>

                                {emailList.length > 0 && (
                                    <Box>
                                        <Text fontSize="sm" fontWeight="medium" mb={2}>Added emails:</Text>
                                        <Wrap spacing={2}>
                                            {emailList.map((email) => (
                                                <WrapItem key={email}>
                                                    <Tag size="md" borderRadius="full" variant="solid" colorScheme="teal">
                                                        <TagLabel>{email}</TagLabel>
                                                        <TagCloseButton onClick={() => handleRemoveEmail(email)} />
                                                    </Tag>
                                                </WrapItem>
                                            ))}
                                        </Wrap>
                                    </Box>
                                )}
                            </Stack>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Stack>
            <Modal isOpen={showResults} onClose={() => setShowResults(false)} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Invitation Result</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={4}>
                            <Flex gap={4}>
                                {successEmails.length > 0 && <Box flex={1}>
                                    <Text fontWeight="medium" mb={2}>Successfully Invited</Text>
                                    <Stack spacing={2}>
                                        {successEmails.map((email, index) => (
                                            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="green" width="fit-content">
                                                <TagLabel>{email}</TagLabel>
                                            </Tag>
                                        ))}
                                    </Stack>
                                </Box>}
                                {failedEmails.length > 0 && <Box flex={1}>
                                    <Text fontWeight="medium" mb={2}>Failed Invitations</Text>
                                    <Stack spacing={2}>
                                        {failedEmails.map((email, index) => (
                                            <Tag key={index} size="md" borderRadius="full" variant="solid" colorScheme="red" width="fit-content">
                                                <TagLabel>{email}</TagLabel>
                                            </Tag>
                                        ))}
                                    </Stack>
                                </Box>}
                            </Flex>
                            {shareLink && (
                                <Box>
                                    <Text fontWeight="medium" mb={2}>Share Link</Text>
                                    <Flex gap={2}>
                                        <Input value={shareLink} isReadOnly />
                                        <Button
                                            leftIcon={<FiCopy />}
                                            colorScheme="teal"
                                            onClick={handleCopyShareLink}
                                        >
                                            Copy
                                        </Button>
                                    </Flex>
                                    {copySuccess && (
                                        <Text color="green.500" fontSize="sm" mt={2}>
                                            Share link has been copied to clipboard!
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box >
    );
}

const mapStateToProps = (state: any) => ({
    selectedSegment: state.selectedSegment.currentSegment,
    editSegment: state.selectedSegment.editSegment,
    editTasksSegments: state.selectedSegment.editTasksSegments,
    TasksSegments: state.selectedSegment.tasksSegments,
    user: state.user,
    language: state.videoForm.language,
    gender: state.videoForm.gender
});

export default connect(mapStateToProps)(VideoForm);
