import React, { useEffect } from "react"
import { Stack, Text, Button, Avatar, Card, CardBody, CardHeader, Input, Textarea, CardFooter, Box, Container, WrapItem, FormControl, FormLabel } from "@chakra-ui/react"
import "./pages.scss"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { HiCheckCircle } from "react-icons/hi";
import { IoDownloadOutline } from "react-icons/io5";
import { Dropzone } from "../../../helpers/Dropzone.tsx"
import { Api, UserDto, handleApiError, baseApiParams, HttpResponse } from 'my-api-client-package'
import { useNavigate } from "react-router-dom"
import { Notification } from "auth-components-package"
import { Bildung } from "./Bildung.tsx"
import DropdownMenu from "../../../helpers/DropdownMenu.tsx"
import store from "../../../store/store.js"
import { useSelector } from "react-redux"

export const Profile = () => {
    const { t } = useTranslation();
    const token = useSelector((state: any) => state.token);
    const userObj = useSelector((state: UserDto | any) => state.user);
    const navigate = useNavigate();

    const profile = useSelector((state: any) => state.profile);
    const eduLevel = profile.eduLevel || userObj.education;
    const specialistArea = profile.specialistArea || userObj.speciality;
    const specialization = profile.specialization || userObj.specialization;
    const languages = [
        { key: t('rate_my.language_de') },
        { key: t('rate_my.language_en') }
    ]
    const preference = useSelector((state: any) => state.preference);
    const language = preference.language || (userObj.preferences !== null && userObj.preferences !== "" && userObj.preferences[0] === "english" ? "English" : "Deutsch");
    const [alertMessage, setAlertMessage] = useState<string | null>(null)
    const [alertHeading, setAlertHeading] = useState<string | null>(null)
    const [buttonText, setButtonText] = useState<string | null>(null)

    const [alertType, setAlertType] = useState<string>('success')

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [infoNotify, setInfoNotify] = useState<boolean>(false);

    const engVersion = "67375cbee8cff4eb470c0729";
    const gerVersion = "672db135127b502557ba057b";
    const handleImageUrl = (url: string) => {
        setImageUrl(url);
    }
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const handleFilesChange = (files: File[]) => {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target?.result as string); // Set the base64 image URL
            };
            reader.readAsDataURL(files[0]);
        }
    };


    const [formValues, setFormValues] = useState({
        username: userObj.username || "",
        title: userObj.titel || "",
        firstName: userObj.firstname || "",
        lastName: userObj.lastname || "",
        paymentAllowed: userObj.paymentAllowed || false,
        phone: userObj.phone || "",
        accountFirstName: userObj.firstname || "",
        accountLastName: userObj.lastname || "",
        shortCv: userObj.shortCv || "",
        email: userObj.email || "",

        street: "",
        location: "",
        postalCode: "",
        iban: "",
        bank: "",
        bic: "",
    });


    const updateUserInfo = async () => {
        const userData: UserDto | any = {
            id: userObj.id,
            email: formValues.email,
            firstname: formValues.firstName,
            lastname: formValues.lastName,
            titel: formValues.title,
            phone: formValues.phone,
            username: formValues.username,
            shortCv: formValues.shortCv,
            avatar: imageUrl
        }
        if (token) {
            try {
                const user = await api.user.saveUser(userData, baseApiParams());
                store.dispatch({ type: "user/setUser", payload: user.data });
                setAlertType('success')
                if (formValues.email !== userObj.email) {
                    await api.user.changeEmail({ mail: formValues.email }, baseApiParams());
                    setInfoNotify(true);
                    setAlertHeading(t("rate_my.notification_confirm_email_heading"))
                    setAlertMessage(t("rate_my.notification_confirm_email_message"));
                    setButtonText("Ok")
                } else {
                    setAlertHeading("")
                    setAlertMessage(t("messages.information_updated_successfully"));
                    setButtonText("Skip")
                }
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    }

    const updateEducationInfo = async () => {
        const data: UserDto | any = {
            email: userObj.email,
            id: userObj.id,
            education: eduLevel,
            speciality: specialistArea,
            specialization: specialization,
        }
        if (token) {
            try {
                const user = await api.user.saveUser(data, baseApiParams());
                setAlertType('success')
                setAlertMessage(t("messages.information_updated_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    }

    const handleDownload = async (id: any) => {
        if (token) {
            try {
                const mediaName = await api.media.getMedia(id, baseApiParams());
                const mediaData = await api.download.getMediaDownload(id, baseApiParams())
                const dataType = "application/pdf";
                const response = await fetch(mediaData.data.message);
                const data = await response.arrayBuffer();
                const blob = new Blob([data], { type: dataType });
                const url = window.URL.createObjectURL(blob);
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.download = mediaName.data.description;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
                setAlertType('success')
                setAlertMessage(t("messages.information_download_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    };


    const [isAddressSectionModified, setIsAddressSectionModified] = useState(false);
    const [isAccountSectionModified, setIsAccountSectionModified] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            const userId = userObj.id.id;

            try {
                const userInfo = await api.user.getUserInfos({ userId }, baseApiParams()) as HttpResponse<any, any>;
                setFormValues({
                    ...formValues,
                    iban: userInfo.data.iban || "",
                    bank: userInfo.data.bank || "",
                    bic: userInfo.data.bic || "",
                    location: userInfo.data.location || "",
                    street: userInfo.data.street || "",
                    postalCode: userInfo.data.zipCode || "",
                });
            } catch (error) {
                console.error(t("messages.error"), error);
            }
        };

        fetchInitialData();
    }, []);

    const handleInputChange = (e: any) => {
        const { name, type, value, checked } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === "checkbox" ? checked : value,
        }));

        if (["username", "title", "firstName", "lastName", "street", "location", "postalCode", "paymenAllowed"].includes(name) && !isAddressSectionModified) {
            setIsAddressSectionModified(true);
        }

        if (["accountFirstName", "accountLastName", "iban", "bank", "bic"].includes(name) && !isAccountSectionModified) {
            setIsAccountSectionModified(true);
        }
    };


    const handlePaymentInfo = async () => {
        const bankInfo = {
            iban: formValues.iban,
            bic: formValues.bic,
            bank: formValues.bank,
            type: "bank",
            location: formValues.location,
            street: formValues.street,
            zipCode: formValues.postalCode,
        }
        try {
            const resp = await api.user.updatePaymentInfo(bankInfo, baseApiParams()) as HttpResponse<any, any>;
            setAlertType('success')
            setAlertMessage(t("messages.information_updated_successfully"))
        }
        catch (e) {
            console.log(e)
            setAlertType('error')
            setAlertMessage(t("messages.error"))
        }
    }

    return (
        <>
            <Text className="page-heading">{t('rate_my.profile')}</Text>
            <Card width="inherit" padding={"20px 15px 20px 15px"} boxShadow={"none"}>
                <Stack>
                    <CardHeader padding={"0px 0px 0px 0px"}>
                        <Text className="profil-info">{t('rate_my.your_profile')}</Text>
                    </CardHeader>
                    <CardBody paddingLeft={{ base: "0", xl: "200px" }} padding={{ base: "0", md: "20px" }}>
                        <Box width={"100%"} className="payment-form-block" gap={"15px"}>
                            <Stack padding={0} gap={{ base: "15px", md: "10px" }}>
                                <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                                    <Stack display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                        <FormControl width={{ base: "100%", md: "215px" }}>
                                            <FormLabel className="input-label">{t('general.username')}</FormLabel>
                                            <Input name="username" type='text' className="payment-input-input" value={formValues.username} onChange={handleInputChange} />
                                        </FormControl>
                                    </Stack>
                                </Container>
                                <Container display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} margin={"0"} width={"100%"} maxW={"auto"} paddingInlineEnd={"0"}>

                                    <Stack display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                        <FormControl minW={"125px"}>
                                            <FormLabel className="input-label">{t('rate_my.title')}</FormLabel>
                                            <Input name="title" type='text' className="payment-input-input" value={formValues.title} onChange={handleInputChange} />
                                        </FormControl>
                                        <FormControl minW={"270px"}>
                                            <FormLabel className="input-label">{t('rate_my.first_name')}</FormLabel>
                                            <Input name="firstName" type='text' className="payment-input-input" value={formValues.firstName} onChange={handleInputChange} />
                                        </FormControl>
                                    </Stack>

                                    <Stack display={"flex"} flexDirection={"row"} gap={{ base: "15px", md: "10px" }} padding={"0"} width={"100%"}>
                                        <FormControl>
                                            <FormLabel className="input-label">{t('general.last_name')}</FormLabel>
                                            <Input name="lastName" type='text' className="payment-input-input" value={formValues.lastName} onChange={handleInputChange} />
                                        </FormControl>
                                    </Stack>
                                </Container>

                                <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"} display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }}>
                                    <Stack display={"flex"} flexDirection={{ base: "column", md: "row" }} gap={{ base: "15px", md: "10px" }} padding={"0"}>
                                        <FormControl minW={"403px"}>
                                            <FormLabel className="input-label">{t('rate_my.email')}</FormLabel>
                                            <Input name="email" type='email' value={formValues.email} onChange={handleInputChange} />
                                        </FormControl>
                                    </Stack>
                                    <Stack display={"flex"} flexDirection={"row"} gap={{ base: "15px", md: "10px" }} padding={"0"} width={"100%"}>
                                        <FormControl>
                                            <FormLabel className="input-label">Phone</FormLabel>
                                            <Input name="phone" type='text' value={formValues.phone} onChange={handleInputChange} />
                                        </FormControl>
                                    </Stack>
                                </Container>

                                <Text className="profil-pic" padding={"16px 0px 0px 20px"}>{t("rate_my.profile_picture")}</Text>
                                <Box as="section" bg="bg.surface" display={'flex'} flexDirection={'column'} gap={"6px"} >
                                    <Container maxW="lg" display={'flex'} flexDirection={{ base: 'column', xl: 'row' }} gap={"16px"} maxWidth={'100%'} padding={0}>
                                        <WrapItem display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                            <Avatar name={`${userObj.firstname} ${userObj.lastname}`} size={'xl'} src={imageSrc ? imageSrc : userObj.avatar} />
                                        </WrapItem>
                                        <FormControl id="file">
                                            <Dropzone fileFormats={['JPG', 'PNG']} maxW={"100px"} fileSizeLimit={3} selectedFiles={(e) => handleFilesChange(e)} handleImageUrl={(e) => handleImageUrl(e)} />
                                        </FormControl>
                                    </Container>
                                </Box>

                                <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                                    <FormControl>
                                        <FormLabel className="input-label">{t('rate_my.short_cv')}</FormLabel>
                                        <Textarea name="shortCv" placeholder={t('rate_my.hint_for_short_vita')} value={formValues.shortCv} onChange={handleInputChange} />
                                    </FormControl>
                                </Container>

                            </Stack>
                        </Box>

                    </CardBody>
                    <CardFooter padding={{ base: "0 0 20px 0", md: "0 20px 15px 0px" }} justifyContent={{ base: "center", md: "end" }}>
                        <Button variant='outline' className={'save-btn'} color="white"
                            onClick={() => {
                                updateUserInfo();
                            }}>{t('rate_my.save_btn')}</Button>
                    </CardFooter>

                </Stack>

                <Stack>
                    <CardHeader padding={"0px"}>
                        <Text className="profil-info">{t('rate_my.address')}</Text>
                    </CardHeader>
                    <CardBody paddingLeft={{ base: "0", xl: "190px" }}>
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">{t('rate_my.street_house_number')}</FormLabel>
                                <Input name="street" type='text' className="payment-input-input" value={formValues.street} onChange={handleInputChange} />
                            </FormControl>
                        </Container>

                        <Container width={"100%"} margin={"0"} maxW={'auto'} flexDirection={{ base: "column", md: "row" }} display={'flex'} gap={{ base: "15px", md: "10px" }} paddingInlineEnd={"0"}>
                            <FormControl maxW={{ base: "100%", md: "50%" }}>
                                <FormLabel className="input-label">{t('rate_my.location')}</FormLabel>
                                <Input name="location" type='text' className="payment-input-input" value={formValues.location} onChange={handleInputChange} />
                            </FormControl>
                            <FormControl maxW={{ base: "100%", md: "50%" }}>
                                <FormLabel className="input-label">{t('rate_my.postal_code')}</FormLabel>
                                <Input name="postalCode" type='text' className="payment-input-input" value={formValues.postalCode} onChange={handleInputChange} />
                            </FormControl>
                        </Container>
                    </CardBody>
                    <CardFooter padding={{ base: "0 0 20px 0", md: "0 30px 15px 0px" }} justifyContent={{ base: "center", md: "end" }}>
                        <Button className={`save-btn`} color="white" variant='outline' onClick={() => handlePaymentInfo()}>
                            {t('rate_my.save')}
                        </Button>
                    </CardFooter>
                </Stack>
                <Stack>
                    <CardHeader padding={"0px"}>
                        <Text className="profil-info">{t('general.education')}</Text>
                    </CardHeader>
                    <CardBody paddingLeft={{ base: "0", xl: "190px" }}>
                        <Bildung showInfo={true} />
                    </CardBody>
                    <CardFooter padding={{ base: "0 0 20px 0", md: "0 30px 15px 0px" }} justifyContent={{ base: "center", md: "end" }}>
                        <Button className={`save-btn`}
                            color="white" variant='outline' onClick={() => {
                                updateEducationInfo();
                            }}>{t('rate_my.save_btn')}</Button>
                    </CardFooter>
                </Stack>

                <Stack>
                    <CardHeader padding={"0px"}>
                        <Text className="conditions-heading">{t('rate_my.conditions')}</Text>
                        <Stack direction={"row"} alignItems={"baseline"} padding={"15px 0px 0px 0px"}>
                            <HiCheckCircle color="teal" />
                            <Text className="date-info">{"seit 16.Jan.2024"}</Text>
                        </Stack>
                    </CardHeader>
                    <CardBody padding={"0px 0px 0px 0px"}>
                        <Stack className="conditions-content">
                            <Text className="language-text">{t('general.language')}</Text>
                            <Stack className="language-dropdown" padding={"50px 0px 30px 0px"}>
                                <DropdownMenu
                                    label={language}
                                    value={language?.key}
                                    options={languages}
                                    onSelect={(e) => {
                                        store.dispatch({ type: "preference/setLanguage", payload: e });
                                    }}
                                />
                            </Stack>
                            <Button className="download-btn" background={"#319795"} color={"white"} leftIcon={<IoDownloadOutline />} onClick={() => {
                                if (language === "Deutsch") {
                                    handleDownload(gerVersion);
                                } else {
                                    handleDownload(engVersion)
                                }
                            }}>{t('rate_my.download_btn')}</Button>
                        </Stack>
                    </CardBody>
                </Stack>
            </Card>
            {alertMessage && <Notification heading={alertHeading} message={alertMessage} type={alertType} buttonText={buttonText} onClose={() => setAlertMessage(null)} infoNotify={infoNotify} />}
        </>
    )
}