import { Card, Text, Spinner, VStack, FormLabel, FormControl, Container, Flex, Input, Select, Button, Badge, Box, Stack, InputGroup, InputLeftElement, Menu, MenuButton, MenuList, MenuItem, InputRightElement } from "@chakra-ui/react";
import { FiCalendar, FiClock, FiSearch, FiChevronDown } from "react-icons/fi";
import { useEffect } from "react";
import { useState } from "react";
import { baseApiParams } from "my-api-client-package";
import { Api } from "my-api-client-package";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CompanyUsers = (props: any) => {
    const user = useSelector((state: any) => state.user);
    const [users, setUsers] = useState<any[]>([]);
    const [savedUser, setSavedUser] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [editedUser, setEditedUser] = useState<any>(null);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [isOpen, setIsOpen] = useState(false);
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });
    const { t } = useTranslation();
    useEffect(() => {
        api.company.findAllCompanyMembers(user.company.id, baseApiParams())
            .then((res: any) => {
                setUsers(res.data);
                if (res.data && res.data.length > 0) {
                    setSelectedUser(res.data[0]);
                }
            });
    }, []);

    useEffect(() => {
        if (selectedUser) {
            setEditedUser({ ...selectedUser });
            console.log(selectedUser.lastLogin);
        }
    }, [selectedUser]);

    const handleSave = () => {
        if (editedUser) {
            const userToSave = {
                ...editedUser,
                avatar: null
            };

            api.user.saveUser(userToSave, baseApiParams())
                .then(() => {
                    setSelectedUser(userToSave);
                    props.setNotificationHeading(t("smartslide.success_state"));
                    props.setNotificationMessage("Der Benutzer wurde erfolgreich gespeichert.");
                    props.setNotificationType("success");
                    setUsers(users.map(u =>
                        u.id.id === editedUser.id.id ? editedUser : u
                    ));
                    setSavedUser(true);
                }).catch((err: any) => {
                    console.log(err);
                });
        }
    };

    const filteredUsers = users.filter(user => {
        const firstName = user?.firstname?.toLowerCase() || '';
        const lastName = user?.lastname?.toLowerCase() || '';
        const searchLower = searchQuery.toLowerCase();

        return firstName.includes(searchLower) || lastName.includes(searchLower);
    });

    return (
        <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 4, md: 6 }}>
            {users.length === 0 && <Spinner size="md" color="#2C7A7B" />}
            {users.length > 0 && (
                <>
                    {/* Mobile View - Searchable Dropdown */}
                    <Box display={{ base: 'block', md: 'none' }} width="100%">
                        <Menu isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
                            <MenuButton
                                as={Button}
                                rightIcon={<FiChevronDown />}
                                width="100%"
                                bg="white"
                                border="1px solid"
                                borderColor="gray.200"
                                _hover={{ bg: 'white' }}
                                _active={{ bg: 'white' }}
                            >
                                {selectedUser ? `${selectedUser.firstname} ${selectedUser.lastname}` : 'Benutzer auswählen'}
                            </MenuButton>
                            <MenuList maxH="300px" className="small-blocks-scroll" overflowY="auto">
                                <Box p={2}>
                                    <InputGroup size="sm">
                                        <InputLeftElement pointerEvents="none">
                                            <FiSearch color="gray.300" />
                                        </InputLeftElement>
                                        <Input
                                            placeholder="Benutzer suchen..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </InputGroup>
                                </Box>
                                {filteredUsers.map((user) => (
                                    <MenuItem
                                        key={user.id.id}
                                        onClick={() => {
                                            setSelectedUser(user);
                                            setIsOpen(false);
                                        }}
                                        bg={selectedUser?.id?.id === user.id.id ? "gray.100" : "transparent"}
                                    >
                                        {user.firstname} {user.lastname}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                    </Box>

                    {/* Desktop View - Card List */}
                    <Card
                        display={{ base: 'none', md: 'block' }}
                        width="300px"
                        padding="15px"
                        height="600px"
                        overflowY="auto"
                    >
                        {users.map((user) => (
                            <Text
                                key={user.id.id}
                                cursor="pointer"
                                padding="8px"
                                backgroundColor={selectedUser?.id?.id === user.id.id ? "gray.100" : "transparent"}
                                _hover={{ backgroundColor: "gray.50" }}
                                onClick={() => setSelectedUser(user)}
                            >{user.firstname} {user.lastname}</Text>
                        ))}
                    </Card>
                </>
            )}

            {/* Benutzerdetails */}
            {selectedUser && editedUser && (
                <Card padding="15px" flex="1">
                    <Text fontSize="lg" fontWeight="bold" mb={4}>Benutzerdetails</Text>
                    <VStack spacing={4} align="stretch">
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                            <Box flex="1">
                                <FormControl>
                                    <FormLabel className="input-label">Titel</FormLabel>
                                    <Input
                                        name="titel"
                                        type="text"
                                        className="payment-input-input"
                                        value={editedUser.titel}
                                        onChange={(e) => setEditedUser({ ...editedUser, titel: e.target.value })}
                                    />
                                </FormControl>
                            </Box>
                            <Box flex="1">
                                <FormControl>
                                    <FormLabel className="input-label">Vorname</FormLabel>
                                    <Input
                                        name="firstname"
                                        type="text"
                                        className="payment-input-input"
                                        value={editedUser.firstname}
                                        onChange={(e) => setEditedUser({ ...editedUser, firstname: e.target.value })}
                                    />
                                </FormControl>
                            </Box>
                            <Box flex="1">
                                <FormControl>
                                    <FormLabel className="input-label">Nachname</FormLabel>
                                    <Input
                                        name="lastname"
                                        type="text"
                                        className="payment-input-input"
                                        value={editedUser.lastname}
                                        onChange={(e) => setEditedUser({ ...editedUser, lastname: e.target.value })}
                                    />
                                </FormControl>
                            </Box>
                        </Stack>
                        <Box>
                            <FormControl>
                                <FormLabel className="input-label">E-Mail</FormLabel>
                                <Input
                                    name="email"
                                    type="email"
                                    className="payment-input-input"
                                    value={editedUser.email}
                                    onChange={(e) => setEditedUser({ ...editedUser, email: e.target.value })}
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel className="input-label">Rolle</FormLabel>
                                <Select
                                    value={editedUser.role}
                                    onChange={(e) => setEditedUser({ ...editedUser, role: e.target.value })}
                                    placeholder="Select Role"
                                    fontSize="12px"
                                >
                                    <option value="Admin">Admin</option>
                                    <option value="User">User</option>
                                    <option value="Entwickler">Entwickler</option>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel className="input-label">Adresse</FormLabel>
                                <Input
                                    name="address"
                                    type="text"
                                    className="payment-input-input"
                                    value={editedUser.address}
                                    onChange={(e) => setEditedUser({ ...editedUser, address: e.target.value })}
                                />
                            </FormControl>
                        </Box>
                        <Text mb={2} fontSize="12px">
                            <b>Letzter Login:</b> {(() => {
                                const formatDate = (date: Date | null) => (
                                    <Stack direction={{ base: 'column', md: 'row' }} spacing={2}>
                                        <Badge bg="#2c7a7b1a" color="#2c7a7b" borderRadius="5px" padding="5px" fontSize="12px">
                                            <Flex align="center" minWidth="100px">
                                                <FiCalendar style={{ marginRight: '4px' }} />
                                                {date ? new Intl.DateTimeFormat('de-DE', {
                                                    dateStyle: 'medium'
                                                }).format(date) : '-'}
                                            </Flex>
                                        </Badge>

                                        <Badge bg="#2c7a7b1a" color="#2c7a7b" borderRadius="5px" padding="5px" fontSize="12px">
                                            <Flex align="center" minWidth="100px">
                                                <FiClock style={{ marginRight: '4px' }} />
                                                {date ? new Intl.DateTimeFormat('de-DE', {
                                                    timeStyle: 'medium'
                                                }).format(date) : '-'}
                                            </Flex>
                                        </Badge>
                                    </Stack>
                                );

                                if (!selectedUser.lastLogin) {
                                    return formatDate(null);
                                }

                                try {
                                    const cleanTimestamp = selectedUser.lastLogin.split('[')[0];
                                    const date = new Date(cleanTimestamp);
                                    return formatDate(date);
                                } catch (e) {
                                    console.error('Fehler beim Parsen des Datums:', e);
                                    return formatDate(null);
                                }
                            })()}
                        </Text>
                        <Button bg="#2C7A7B" color="white" onClick={handleSave} _hover={{ bg: "#2C7A7B" }}>
                            Änderungen speichern
                        </Button>
                    </VStack>
                </Card>
            )}
        </Stack>
    )
}

export default CompanyUsers;