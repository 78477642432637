import React from "react";
import { Card, Flex, VStack, Container, Text, Stack, FormControl, FormLabel, Select, Button, Input, Textarea, Box, Spinner, Icon, ModalFooter, ModalBody, ModalCloseButton, ModalHeader, ModalOverlay, ModalContent, Modal } from "@chakra-ui/react";
import { Api, baseApiParams, Identifiable, WorkflowlibraryDto } from "my-api-client-package";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import WorkflowLibraryService from "../../../../services/WorkflowLibraryService";
import { ArrowRightIcon, ArrowLeftIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import store from "../../../../store/store";
import {
    setToWorkflows,
    setFromWorkflows,
    setSelectedFromWorkflowLibrary,
    setSelectedToWorkflowLibrary,
    moveToRight,
    moveToLeft,
    setSelectedFromWorkflow,
    setSelectedToWorkflow
} from "../../../../store/contentManagementSlice";
import { Notification } from "auth-components-package";
import Swal from "sweetalert2";

interface WorkflowLibrary {
    id: string;
    label: string;
}

interface Workflow {
    id: string;
    label: string;
}

const ContentManagement = () => {
    const api = new Api();
    const user = useSelector((state: any) => state.user);

    const [allWorkflowLibraries, setAllWorkflowLibraries] = useState<WorkflowLibrary[]>([]);

    const [alreadyExistError, setAlreadyExistError] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<{ from: boolean; to: boolean; delete: { from: boolean; to: boolean } }>({ 
        from: false, 
        to: false, 
        delete: { from: false, to: false } 
    });

    const selectedFromWorkflowLibrary = useSelector((state: any) => state.contentManagement.selectedFromWorkflowLibrary);
    const selectedToWorkflowLibrary = useSelector((state: any) => state.contentManagement.selectedToWorkflowLibrary);

    const fromWorkflows = useSelector((state: any) => state.contentManagement.fromWorkflows);
    const toWorkflows = useSelector((state: any) => state.contentManagement.toWorkflows);

    const selectedFromWorkflow = useSelector((state: any) => state.contentManagement.selectedFromWorkflow);
    const selectedToWorkflow = useSelector((state: any) => state.contentManagement.selectedToWorkflow);

    const [libraryLabel, setLibraryLabel] = useState<string>("");
    const [libraryDescription, setLibraryDescription] = useState<string>("");

    const [isOpen, setIsOpen] = useState(false);
    const [newLibraryLabel, setNewLibraryLabel] = useState("");
    const [newLibraryDescription, setNewLibraryDescription] = useState("");

    useEffect(() => {
        const fetchWorkflowLibraries = async () => {
            try {
                const response: any = await api.company.findAllWorkflowLibraries(user.company.id, baseApiParams());
                setAllWorkflowLibraries(response.data);
            } catch (error) {
                console.error('Error fetching workflow libraries:', error);
            }
        };
        fetchWorkflowLibraries();
    }, [user.company.id]);

    const fetchWorkflows = async (workflowLibrary: WorkflowLibrary | undefined, type: 'from' | 'to') => {
        if (!workflowLibrary) return;

        const workflowLibraryService = new WorkflowLibraryService(workflowLibrary);
        if (!workflowLibraryService.getWorkflows) {
            setIsLoading(prev => ({ ...prev, [type]: false }));
            return;
        }

        try {
            setIsLoading(prev => ({ ...prev, [type]: true }));
            const data = await api.workflowlibrary.getWorkflowlibraryWorkflows(workflowLibraryService.getWorkflowLibraryID, baseApiParams());
            const workflows = Array.isArray(data.data) ? data.data : [data.data];

            if (type === 'from') {
                store.dispatch(setFromWorkflows(workflows));
            } else {
                store.dispatch(setToWorkflows(workflows));
            }
        } catch (error) {
            console.error(`Error fetching ${type} workflows:`, error);
        } finally {
            setIsLoading(prev => ({ ...prev, [type]: false }));
        }
    };

    const handleWorkflowLibraryChange = (e: React.ChangeEvent<HTMLSelectElement>, type: 'from' | 'to') => {
        const selectedWorkflow = allWorkflowLibraries.find(w => w.id.id === e.target.value);
        if (type === 'from') {
            store.dispatch(setFromWorkflows([]));
            store.dispatch(setSelectedFromWorkflowLibrary(selectedWorkflow));
            setLibraryLabel("");
            setLibraryDescription("");
            fetchWorkflows(selectedWorkflow, 'from');
        } else {
            store.dispatch(setToWorkflows([]));
            store.dispatch(setSelectedToWorkflowLibrary(selectedWorkflow));
            fetchWorkflows(selectedWorkflow, 'to');
        }
    };

    const handleWorkflowMove = async (direction: 'toLeft' | 'toRight') => {
        const sourceWorkflow = direction === 'toLeft' ? selectedToWorkflow : selectedFromWorkflow;
        const targetList = direction === 'toLeft' ? fromWorkflows : toWorkflows;

        if (targetList.some((item: Workflow) => item.id === sourceWorkflow.id)) {
            setAlreadyExistError(true);
            return;
        }

        if (direction === 'toLeft') {
            store.dispatch(moveToLeft(selectedToWorkflow));
            const workflowLibraryRevised: WorkflowlibraryDto = {
                ...selectedFromWorkflowLibrary,
                workflows: selectedFromWorkflowLibrary.workflows ? 
                    [...selectedFromWorkflowLibrary.workflows, sourceWorkflow.id] :
                    [sourceWorkflow.id]
            }
            store.dispatch(setSelectedFromWorkflowLibrary(workflowLibraryRevised));
            api.workflowlibrary.saveWorkflowlibrary(workflowLibraryRevised, baseApiParams()).then((response: any) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Workflow library updated successfully'
                });
            }).catch((error: any) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update workflow library'
                });
                console.error('Error adding workflow library:', error);
            });
        } else {
            store.dispatch(moveToRight(selectedFromWorkflow));
            const workflowLibraryRevised: WorkflowlibraryDto = {
                ...selectedToWorkflowLibrary,
                workflows: selectedToWorkflowLibrary.workflows ?
                    [...selectedToWorkflowLibrary.workflows, sourceWorkflow.id] :
                    [sourceWorkflow.id]
            }
            store.dispatch(setSelectedToWorkflowLibrary(workflowLibraryRevised));
            api.workflowlibrary.saveWorkflowlibrary(workflowLibraryRevised, baseApiParams()).then((response: any) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Workflow library updated successfully'
                });
            }).catch((error: any) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to update workflow library'
                });
                console.error('Error adding workflow library:', error);
            });
        }
    };

    const handleWorkflowDelete = (workflow: Workflow, direction: 'left' | 'right') => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(prev => ({
                    ...prev,
                    delete: {
                        from: direction === 'left',
                        to: direction === 'right'
                    }
                }));

                const sourceWorkflowLibrary = direction === 'left' ? selectedFromWorkflowLibrary : selectedToWorkflowLibrary;
                const filteredWorkflows = sourceWorkflowLibrary.workflows.filter((w: Identifiable) => w.id !== workflow.id.id)
                const workflowLibraryRevised: WorkflowlibraryDto = {
                    ...sourceWorkflowLibrary,
                    workflows: filteredWorkflows
                }

                api.workflowlibrary.saveWorkflowlibrary(workflowLibraryRevised, baseApiParams())
                    .then(async (response: any) => {
                        const data = await api.workflowlibrary.getWorkflowlibraryWorkflows(response.data.id.id, baseApiParams());
                        if (direction === 'left') {
                            store.dispatch(setSelectedFromWorkflowLibrary(workflowLibraryRevised));
                            store.dispatch(setFromWorkflows(data.data));
                        } else {
                            store.dispatch(setSelectedToWorkflowLibrary(workflowLibraryRevised));
                            store.dispatch(setToWorkflows(data.data));
                        }

                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Workflow removed successfully'
                        });
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Failed to remove workflow'
                        });
                        console.error('Error removing workflow:', error);
                    })
                    .finally(() => {
                        setIsLoading(prev => ({
                            ...prev,
                            delete: {
                                from: false,
                                to: false
                            }
                        }));
                    });
            }
        });
    }

    const handleEditWorkflowLibrary = (workflowLibrary: any) => {
        setLibraryLabel(workflowLibrary.label);
        setLibraryDescription(workflowLibrary.description);
    }

    const handleUpdateWorkflowLibrary = () => {
        const workflowLibraryRevised: WorkflowlibraryDto = {
            ...selectedFromWorkflowLibrary,
            label: libraryLabel,
            description: libraryDescription
        }

        api.workflowlibrary.saveWorkflowlibrary(workflowLibraryRevised, baseApiParams()).then((response: any) => {
            console.log(response);
            const updatedWorkflowLibraryList = allWorkflowLibraries.map((workflowLibrary: any) => {
                if (workflowLibrary.id.id === selectedFromWorkflowLibrary.id.id) {
                    return {
                        ...workflowLibrary,
                        label: libraryLabel,
                        description: libraryDescription
                    }
                }
                return workflowLibrary;
            })
            setAllWorkflowLibraries(updatedWorkflowLibraryList);
            store.dispatch(setSelectedFromWorkflowLibrary(response.data));
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Workflow library updated successfully'
            });
        }).catch((error: any) => {
            console.error('Error updating workflow library:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update workflow library'
            });
        });
    }

    const handleAddLibrary = () => {
        const newLibrary: any = {
            label: newLibraryLabel,
            description: newLibraryDescription
        }
        
        api.workflowlibrary.addWorkflowlibrary(newLibrary, baseApiParams()).then((response: any) => {
            setAllWorkflowLibraries([...allWorkflowLibraries, response.data]);
            setIsOpen(false);
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Workflow library added successfully'
            });
        }).catch((error: any) => {
            console.error('Error adding workflow library:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to add workflow library'
            });
        });
    }

    return (
        <Card padding="15px" flex="1">
            <Flex justifyContent={"space-between"}>
                <Text fontSize="sm" fontWeight="bold" mb={4}>Content Management</Text>
                <Button variant={"outline"} borderColor={"#2C7A7B"} _hover={{ bg: "#2C7A7B", color: "white" }} onClick={() => setIsOpen(true)}>Add Library</Button>
            </Flex>
            <Stack gap="15px">
                <VStack spacing={4} align="stretch">
                    <Flex gap="20px" alignItems="flex-start">
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">From Workflowlibraries</FormLabel>
                                <Select
                                    value={selectedFromWorkflowLibrary?.id.id || ""}
                                    onClick={() => {
                                        store.dispatch(setFromWorkflows([]));
                                    }}
                                    onChange={(e) => {
                                        handleWorkflowLibraryChange(e, 'from');
                                    }}
                                    placeholder="Select Workflow"
                                    fontSize="12px"
                                >
                                    {allWorkflowLibraries.map((workflow: any) => (
                                        <option value={workflow.id.id} key={workflow.id.id}>{workflow.label}</option>
                                    ))}
                                </Select>
                                <Button marginTop={"5px"} size={"xs"} colorScheme="teal" variant={"outline"} isDisabled={!selectedFromWorkflowLibrary} onClick={() => handleEditWorkflowLibrary(selectedFromWorkflowLibrary)}>
                                    <Icon as={EditIcon} />
                                </Button>
                            </FormControl>
                        </Container>

                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">To Workflowlibraries</FormLabel>
                                <Select
                                    value={selectedToWorkflowLibrary?.id.id || ""}
                                    onClick={() => {
                                        store.dispatch(setToWorkflows([]));
                                    }}
                                    onChange={(e) => {
                                        handleWorkflowLibraryChange(e, 'to');
                                    }}
                                    placeholder="Select Workflow"
                                    fontSize="12px"
                                >
                                    {allWorkflowLibraries.map((workflow: any) => (
                                        <option value={workflow.id.id} key={workflow.id.id}>{workflow.label}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Container>

                    </Flex>
                </VStack>

                <VStack spacing={4} align="stretch">
                    <Flex gap="10px">
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">From Workflows</FormLabel>
                                <Stack spacing={2} maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md" p={2}>
                                    {isLoading.delete.from && (
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100px" gap={2}>
                                            <Spinner color="teal.500" size="xl" />
                                            <Text color="teal.500">Deleting...</Text>
                                        </Box>
                                    )}
                                    {!isLoading.delete.from && (fromWorkflows ? fromWorkflows.map((workflow: any) => (
                                        <Flex key={workflow.id.id} gap="10px">
                                            <Box
                                                padding="3px 5px"
                                                bg={selectedFromWorkflow && selectedFromWorkflow.label === workflow.label ? "#2C7A7B" : "gray.50"}
                                                color={selectedFromWorkflow && selectedFromWorkflow.label === workflow.label ? "white" : "black"}
                                                borderRadius="md"
                                                cursor="pointer"
                                                _hover={{ bg: selectedFromWorkflow && selectedFromWorkflow.label === workflow.label ? "teal.600" : "gray.100" }}
                                                width={"100%"}
                                                onClick={() => {
                                                    store.dispatch(setSelectedFromWorkflow(workflow))
                                                }}
                                                transition="all 0.2s"
                                            >
                                                {workflow.label}
                                            </Box>
                                            <Button size={"sm"} colorScheme="red" onClick={() => handleWorkflowDelete(workflow, 'left')}><Icon as={DeleteIcon} /></Button>
                                        </Flex>
                                    )) : (
                                        <Box p={2}>
                                            <Text>No workflows found</Text>
                                        </Box>
                                    ))}
                                    {isLoading.from && <Spinner color={"#2C7A7B"} size="sm" />}
                                </Stack>
                            </FormControl>
                        </Container>
                        <Container width={"auto"} display={"flex"} flexDirection={"column"} gap="10px" alignItems={"center"} justifyContent={"flex-start"} paddingTop={"25px"}>
                            <Button size={"sm"}
                                onClick={() => handleWorkflowMove('toLeft')}
                                disabled={selectedToWorkflow.length < 1 ? true : false}
                            ><ArrowLeftIcon color={"#2C7A7B"} /></Button>
                            <Button size={"sm"}
                                onClick={() => handleWorkflowMove('toRight')}
                                disabled={selectedFromWorkflow.length < 1 ? true : false}
                            >
                                <ArrowRightIcon color={"#2C7A7B"} /></Button>
                        </Container>
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"} paddingLeft={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">To Workflows</FormLabel>

                                <Stack spacing={2} maxH="200px" overflowY="auto" borderWidth="1px" borderRadius="md" p={2}>
                                    {isLoading.delete.to && (
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100px" gap={2}>
                                            <Spinner color="teal.500" size="xl" />
                                            <Text color="teal.500">Deleting...</Text>
                                        </Box>
                                    )}
                                    {!isLoading.delete.to && (toWorkflows ? toWorkflows.map((workflow: any) => (
                                        <Flex key={workflow.id.id} gap="10px">
                                            <Box
                                                padding="3px 5px"
                                                bg={selectedToWorkflow && selectedToWorkflow.label === workflow.label ? "#2C7A7B" : "gray.50"}
                                                color={selectedToWorkflow && selectedToWorkflow.label === workflow.label ? "white" : "black"}
                                                borderRadius="md"
                                                cursor="pointer"
                                                _hover={{ bg: selectedToWorkflow && selectedToWorkflow.label === workflow.label ? "teal.600" : "gray.100" }}
                                                onClick={() => {
                                                    store.dispatch(setSelectedToWorkflow(workflow))
                                                }}
                                                transition="all 0.2s"
                                                width={"100%"}
                                            >
                                                {workflow.label}
                                            </Box>
                                            <Button size={"sm"} colorScheme="red" onClick={() => handleWorkflowDelete(workflow, 'right')}><Icon as={DeleteIcon} /></Button>
                                        </Flex>
                                    )) : (
                                        <Box p={2}>
                                            <Text>No workflows found</Text>
                                        </Box>
                                    ))}
                                    {isLoading.to && <Spinner color={"#2C7A7B"} size="sm" />}
                                </Stack>
                            </FormControl>
                        </Container>
                    </Flex>
                </VStack>

                <VStack spacing={4} align="stretch">
                    <Flex gap="10px">
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">Label</FormLabel>
                                <Input
                                    name="Label"
                                    type="text"
                                    className="payment-input-input"
                                    value={libraryLabel}
                                    onChange={(e) => setLibraryLabel(e.target.value)}
                                />
                            </FormControl>
                            <Flex gap="10px" paddingTop="5px">
                                <Button 
                                    bg="#2C7A7B" 
                                    color="white" 
                                    _hover={{ bg: "#2C7A7B" }} 
                                    onClick={() => handleUpdateWorkflowLibrary()} 
                                    disabled={
                                        !libraryLabel.trim() || 
                                        !libraryDescription.trim() || 
                                        (libraryLabel === selectedFromWorkflowLibrary?.label && 
                                        libraryDescription === selectedFromWorkflowLibrary?.description)
                                    }
                                >
                                    Update Library
                                </Button>
                            </Flex>
                        </Container>
                        <Container width={"100%"} margin={"0"} maxW={'auto'} paddingInlineEnd={"0"}>
                            <FormControl>
                                <FormLabel className="input-label">Description</FormLabel>
                                <Textarea
                                    name="description"
                                    className="payment-input-input"
                                    value={libraryDescription}
                                    onChange={(e) => setLibraryDescription(e.target.value)}
                                />
                            </FormControl>
                        </Container>
                    </Flex>
                </VStack>
                {alreadyExistError && <Notification heading={"Error"} message={"Item already exists!"} type={"error"} buttonText={"Ok"} onClose={() => setAlreadyExistError(false)} infoNotify={false} />}

                <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Add New Workflow Library</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack spacing={4}>
                                <FormControl>
                                    <FormLabel>Label</FormLabel>
                                    <Input 
                                        placeholder="Enter library label"
                                        value={newLibraryLabel}
                                        onChange={(e) => setNewLibraryLabel(e.target.value)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <Textarea
                                        placeholder="Enter library description" 
                                        value={newLibraryDescription}
                                        onChange={(e) => setNewLibraryDescription(e.target.value)}
                                    />
                                </FormControl>
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={handleAddLibrary} isDisabled={!newLibraryLabel || !newLibraryDescription}>
                                Add
                            </Button>
                            <Button variant="ghost" onClick={() => setIsOpen(false)}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Stack >
        </Card >
    )
}

export default ContentManagement;