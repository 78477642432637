import React, { useState } from 'react';
import {
    Box,
    Flex,
    useDisclosure,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    Stack,
    IconButton,
    HStack,
} from '@chakra-ui/react';
import './Dashboard.scss';
import { Sidebar } from './Sidebar/Sidebar.tsx';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Mediathek from './Pages/Mediathek.tsx';
import { baseApiParams, UserDto } from 'my-api-client-package';
import store from '../../store/store';
import { MiscFunctions } from 'helper-functions-package';
import { Api } from 'my-api-client-package';
import MiscFunctionsLocal from '../../helpers/MiscFunctions';
import { AllMedia } from './Pages/AllMedia.tsx';
import { Courses } from './Pages/Courses.tsx';
import { Instructions } from './Pages/Instructions.tsx';
import { Admin } from './Pages/Admin/Admin.tsx';
import { Header } from './Header/header.tsx';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Profile } from './Pages/Profile.tsx';
import { SmartVideo } from './Pages/SmartVideo.tsx';
import { clearVideoInteractions } from '../../store/InteractionsSlice.js';
import { clearVideo } from '../../store/videoSlice.js';
import { clearTasksSegments } from '../../store/segmentSlice.js';
import { IdleRefreshComponent } from 'auth-components-package';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setUserSelectedLanguage } from '../../store/languageSlice.ts';

const mapStateToProps = (state: any) => ({
    language: state.language.userLanguage,
    user: state.user,
})

function MediathekDashboard(props: any) {
    const activePage = useSelector((state: any) => state.activeMenu.activeMediathekPage);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [userObj, setUserObj] = useState<UserDto | null>(null);
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    const { i18n } = useTranslation();
    const renderActivePage = () => {
        switch (activePage) {
            case 'Mediathek':
                return <Mediathek />;
            case 'AllMedia':
                return <AllMedia />;
            case 'Courses':
                return <Courses />;
            case 'Instructions':
                return <Instructions />;
            case 'Profile':
                return <Profile />;
            case 'SmartVideo':
                return <SmartVideo />;
            case 'Admin':
                return <Admin />;
        }
    };

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!MiscFunctions.isNull(props.user)) {
            const data = MiscFunctionsLocal.getLanguage(props.user.preferences);
            store.dispatch(setUserSelectedLanguage(data));
        }
        if (props.language === "english") {
            i18n.changeLanguage("en")
        } else if (props.language === "german") {
            i18n.changeLanguage("de")
        }
    }, [props.user])

    useEffect(() => {
        dispatch(clearVideoInteractions());
        dispatch(clearVideo());
        dispatch(clearTasksSegments());
        try {
            api.user.getCurrentUser(baseApiParams()).then((user) => {
                if (user.data && !MiscFunctions.isNull(user.data)) {
                    dispatch({ type: 'user/setUser', payload: user.data })
                    setUserObj(user.data)
                }
            }).catch((error) => {
                if (error && error.status === 401) {
                    MiscFunctionsLocal.logout();
                }
            });
        } catch (error) {
            MiscFunctionsLocal.logout();
            console.error('Error getting current user:', error);
        }
    }, []);

    const handleNavigation = (redirectedTo: string) => {
        navigate(redirectedTo);
    };

    return (
        <Box minH="100vh">
            <IdleRefreshComponent token={'token/setToken'} dispatch={dispatch} navigate={(redirectedTo: string) => handleNavigation(redirectedTo)} t={t} apiURL={process.env.REACT_APP_API_URL || ''} idleTimeout={parseInt(process.env.REACT_APP_IDLE_TIMER || '1200000')} refrestTimeout={parseInt(process.env.REACT_APP_GET_REFRESH_TOKEN_EVERY || '300000')} />
            <HStack
                flex="1"
                maxW={{ base: 'full' }}
                py={{ base: '6', sm: '8' }}
                px={{ base: '4', sm: '6' }}
                height={"72px"}
                boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"}
                position={"fixed"}
                backgroundColor={"#FFF"}
                justifyContent={"space-between"}
                width={"100%"}
                zIndex={"9"}
                className='my-header'
            >
                <IconButton
                    aria-label="Open menu"
                    icon={<HamburgerIcon />}
                    onClick={onOpen}
                    display={{ base: 'block', lg: 'none' }}
                />
                <Header userObj={userObj} />
            </HStack>
            <Flex as="section" minH="90vh">
                {/* Sidebar for larger screens */}
                <Box display={{ base: 'none', lg: 'block' }}>
                    <Sidebar userObj={userObj} />
                </Box>

                {/* Drawer for smaller screens */}
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerOverlay />
                    <DrawerContent className='my-drawer-content'>
                        <DrawerCloseButton />
                        <DrawerHeader>Menu</DrawerHeader>
                        <DrawerBody className='my-drawer-body'>
                            <Sidebar userObj={userObj} />
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>

                {/* Main content */}
                <Box className='my-content-body' minH={"100vh"} backgroundColor={"#eef2f6"} minWidth={"0"}
                >
                    <Stack flex="1"
                        paddingTop={{ base: "20px", sm: "20px" }}
                        paddingBottom={{ base: "20px", sm: "20px" }}
                        paddingLeft={{ base: "10px", sm: "20px" }}
                        paddingRight={{ base: "10px", sm: "20px" }}
                        bg="#eef2f6"
                        borderRightWidth="1px"
                        marginTop={{ base: "80px", sm: "120px" }}
                        minWidth={"0"}
                    >
                        {renderActivePage()}
                    </Stack>
                </Box>
            </Flex>
        </Box >
    );
}

export default connect(mapStateToProps)(MediathekDashboard);