import React from 'react';
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Textarea,
    Tooltip,
    Image,
    Avatar,
    Icon,
} from '@chakra-ui/react'
import { ExternalLinkIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons'
import { FiPlusCircle, FiRefreshCw, FiRotateCw } from "react-icons/fi";
import DOClient from '../../utils/DOClient';
import { useState, useEffect } from 'react';
import { Api, baseApiParams, SegmentDto, PhaseDto, TaskDto, SegmentTimestampDto } from "my-api-client-package";
import store from "../../store/store";
import SpeakerAudio from './SpeakerAudio.tsx';
import { MiscFunctions } from 'helper-functions-package';
import { ProgressButton } from '../Misc/ProgressButton.tsx';
import { connect } from 'react-redux';
import { setEditTaskSegment, clearEditTaskSegment, addTaskSegment, deleteTaskSegment, setCurrentTaskSegment, setTasksSegments } from '../../store/segmentSlice';
import { apiMedia } from '../../helpers/api'
import MiscFunctionsLocal from "../../helpers/MiscFunctions";
import Swal from 'sweetalert2';

const SegmentsForm = (props: any) => {
    const [uploadMedia, setUploadMedia] = useState<any>(null);
    const [alertMessage, setAlertMessage] = useState<any>(null);
    const [alertType, setAlertType] = useState<any>(null);

    const mediaApi = apiMedia;
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    //Sync selectedSegmentState with selectedSegment whenever it changes
    useEffect(() => {

    }, [props.selectedSegment]);

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = e.target.files?.[0];
            const originalFileName = file?.name;
            let width = 0;
            let height = 0;

            // Check image dimensions before upload
            if (file) {
                const img = document.createElement('img');
                const imageUrl = URL.createObjectURL(file);

                await new Promise((resolve, reject) => {
                    img.onload = () => {
                        width = img.width;
                        height = img.height;
                        console.log(`Image dimensions: ${width}x${height}`);
                        URL.revokeObjectURL(imageUrl);
                        resolve(null);
                    };
                    img.onerror = reject;
                    img.src = imageUrl;
                });
            }

            if (originalFileName) {
                const fileName = `${store.getState().user?.id?.id}/${originalFileName}`;
                let encoded = encodeURIComponent(fileName);

                const res = await api.upload.uploadPrivateFile(
                    encodeURIComponent(originalFileName),
                    "Upload picture",
                    { resolution: `${width}x${height}` },
                    baseApiParams()
                )
                let response = await res.data;
                let media = await DOClient.put(
                    response.message,
                    e.target.files?.[0],
                    "Test-Upload"
                );
                const mediaObject = await api.success.confirmUpload(
                    {
                        filename: encoded,
                        mediaProperties: response.description
                    },
                    {
                        ...baseApiParams(),
                        method: "POST"
                    }
                );
                setUploadMedia(mediaObject.data);
                setAlertMessage("Image uploaded successfully");
                setAlertType("success");
                store.dispatch(setEditTaskSegment({ ...(props.editSegment || props.selectedSegment), picture: mediaObject.data.url }));
            }
        } catch (error: any) {
            console.error("Error uploading image:", error);
            setAlertMessage("Error uploading image: ");
            setAlertType("error");
        }
    }

    const handleUpdateSegment = async (segment: PhaseDto | TaskDto) => {
        console.log("segment", segment);

        try {
            await api.datetimestamp.addDatetimestamp({
                starttime: MiscFunctions.DateString2Seconds(segment.timestamp) || 0
            }, baseApiParams()).then(async (res) => {
                const newSegment: TaskDto | PhaseDto | SegmentDto = {
                    id: { id: segment.id, tag: "segment" },
                    label: segment.label,
                    description: segment.description,
                    timestamp: res.data?.id,
                    duration: Number(segment.duration),
                    video: segment.video,
                    segmenttype: segment.segmenttype,
                    level: segment.level,
                    parent: segment.parent,
                    picture: uploadMedia ? uploadMedia.unsignedUrl : undefined,
                    ...(segment.speakeraudios?.[0]?.id && { speakeraudios: [segment.speakeraudios[0].id] })
                };
                try {
                    // const response = await api.segment.saveSegment(newSegment as SegmentDto, baseApiParams());
                    const response = await api.segment.saveSegment(newSegment as SegmentDto | PhaseDto | TaskDto, baseApiParams())
                    store.dispatch(setTasksSegments(store.getState().selectedSegment.editTasksSegments))
                    setAlertMessage("Segment updated successfully");
                    setAlertType("success");
                } catch (error) {
                    console.error("Error updating segment:", error);
                    setAlertMessage("Error updating segment.");
                    setAlertType("error");
                }
                store.dispatch(setCurrentTaskSegment(segment))
            });

        } catch (error) {
            console.error("Error updating segment:", error);
        }
    }

    const handleAddSegment = async (segment: PhaseDto | TaskDto) => {
        console.log("segment", segment);
        try {
            const resourceId = process.env.REACT_APP_API_URL?.includes('dev') ||
                process.env.REACT_APP_API_URL?.includes('localhost')
                ? "674ed5c046716014888c8059"
                : "67a9d23bbd9d391b6641b6cd";

            const newSegmentDto: SegmentTimestampDto = {
                segment: {
                    id: { id: segment.id, tag: "segment" },
                    label: segment.label,
                    description: "Schnitt",
                    ressources: [{ id: resourceId, tag: "ressource" }],
                    duration: Number(segment.duration),
                    video: segment.video,
                    segmenttype: segment.segmenttype,
                    level: "taskgroup",
                    picture: uploadMedia ? uploadMedia.unsignedUrl : undefined,
                    ...(segment.speakeraudios?.[0]?.id && { speakeraudios: [segment.speakeraudios[0].id] })
                },
                timestamp: MiscFunctions.DateString2Seconds(segment.timestamp) || 0
            };

            const response = (await api.segment.addSegment(newSegmentDto as SegmentTimestampDto, baseApiParams())).data
            console.log("response", response);
            store.dispatch(addTaskSegment(response))
            store.dispatch(setCurrentTaskSegment(response))
            setAlertMessage("Segment updated successfully");
            setAlertType("success");
        } catch (error) {
            console.error("Error updating segment:", error);
            setAlertMessage("Error updating segment.");
            setAlertType("error");
        }
    }

    const handleSpeakerText = async (segment: PhaseDto | TaskDto) => {

        const headers = baseApiParams()
        headers['headers']['server'] = process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') || process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN'

        const thread_id = (await (await mediaApi.aiprocessing.speakertextCreate({
            processing: {
                server: process.env.REACT_APP_API_URL?.toLowerCase().includes('dev') || process.env.REACT_APP_API_URL?.toLowerCase().includes('localhost') ? 'DEV' : 'MAIN',
                speakertextproperties: {
                    gender: props.gender,
                    id: (props.editSegment || props.selectedSegment)?.id,
                    language: props.language,
                    speakertext: (props.editSegment || props.selectedSegment)?.description
                }
            }
        }, headers)).json())['thread_id']
        console.log(thread_id)
        let isCompleted = false;
        let speakerText = ""
        while (!isCompleted) {
            const state = (await (await apiMedia.checkStatus.checkStatusDetail(thread_id, headers)).json())['status'];

            if (state === 'Running') {
                await new Promise(resolve => setTimeout(resolve, 10000));
                console.log('running');
            } else if (state === 'Completed') {
                const result = (await (await apiMedia.getResult.getResultDetail(thread_id, headers)).json())['message'];
                speakerText = result.speakertext;
                isCompleted = true;
            } else {

                console.log(`error: ${state}`);
                isCompleted = true;
            }
        }

        if (speakerText !== "") {
            const resourceId = process.env.REACT_APP_API_URL?.includes('dev') ||
                process.env.REACT_APP_API_URL?.includes('localhost')
                ? "674f1b3d4752cd1065d79c1c"
                : "6745af621fa3dd17e2bff267";

            const newSegmentDto: SegmentTimestampDto = {
                segment: {
                    label: segment.label,
                    description: speakerText,
                    ressources: [{ id: resourceId, tag: "ressource" }],
                    duration: MiscFunctionsLocal.estimateSentenceDuration(speakerText),
                    video: segment.video,
                    segmenttype: segment.segmenttype,
                    level: "taskgroup"
                },
                timestamp: MiscFunctions.DateString2Seconds(segment.timestamp) || 0
            };

            const response = (await api.segment.addSegment(newSegmentDto as SegmentTimestampDto, baseApiParams())).data
            console.log("response", response);
            store.dispatch(addTaskSegment(response))
            store.dispatch(setCurrentTaskSegment(response))
            store.dispatch(clearEditTaskSegment());
        }
    }

    const handleDeleteSegment = async (segmentId: string) => {
        console.log("segmentId", segmentId);
        const willDelete = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (!willDelete.isConfirmed) {
            throw new Error("User cancelled deletion");  // Ensures ProgressButton resets
        }

        try {
            await api.segment.deleteSegment(segmentId, baseApiParams());
            store.dispatch(deleteTaskSegment(segmentId));
            setAlertMessage("Segment deleted successfully");
            setAlertType("success");

            await Swal.fire(
                'Deleted!',
                'Your segment has been deleted.',
                'success'
            );
        } catch (error) {
            console.error("Error deleting segment:", error);
            await Swal.fire(
                'Error!',
                'There was a problem deleting the segment.',
                'error'
            );
            throw error; // Ensures button resets on failure
        }
    };

    const handleInputChange = (field: string, value: any) => {

        if (!props.editSegment && props.selectedSegment && props.selectedSegment[field] !== value) {
            store.dispatch(setEditTaskSegment({ ...props.selectedSegment, [field]: value }));
        }

        if (props.editSegment && props.editSegment[field] !== value) {
            store.dispatch(setEditTaskSegment({ ...props.editSegment, [field]: value }));
        }

    }

    const handleResetInput = () => {
        // If the changes are not saved then update the segment with reset values
        if (alertMessage) {
            handleUpdateSegment(props.selectedSegment);
        }
        store.dispatch(clearEditTaskSegment());
    }

    const isMobile = store.getState().isMobile;
    const containerAnnotationRef = store.getState().refs.containerAnnotationRef;
    return (
        <Box as="form" bg="bg.surface" className="segments-form" boxShadow="sm" overflow="auto" borderRadius="lg" {...props} height={!isMobile ? containerAnnotationRef - 60 : "fit-content"}>
            <Stack spacing="5" padding="5px">

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <Stack spacing="3" flexDirection="column" display="flex" alignItems="center" gap="11px">
                        <FormControl id="segmentLabel">
                            <FormLabel className="input-label">Label</FormLabel>
                            <Input
                                value={props.editSegment?.label || props.selectedSegment?.label || ""}
                                onChange={(e) => handleInputChange('label', e.target.value)}
                                className="segments-form-input"
                            />
                        </FormControl>
                        <FormControl id="timestamp">
                            <FormLabel className="input-label">Timestamp</FormLabel>
                            <Input
                                type="time"
                                step="1"
                                value={props.editSegment?.timestamp || props.selectedSegment?.timestamp || ""}
                                onChange={(e) => handleInputChange('timestamp', e.target.value)}
                                className="segments-form-input"
                            />
                        </FormControl>


                        <FormControl id="duration">
                            <FormLabel className="input-label">Duration</FormLabel>
                            <Input
                                type="number"
                                value={props.editSegment?.duration || props.selectedSegment?.duration || ""}
                                onChange={(e) => handleInputChange('duration', Number(e.target.value))}
                                className="segments-form-input"
                            />
                        </FormControl>
                    </Stack>

                    <FormControl id="segmentDescription">
                        <FormLabel className="input-label">Description</FormLabel>
                        <Textarea
                            rows={8}
                            resize="none"
                            borderWidth="1px"
                            className="input-textarea"
                            value={props.editSegment?.description || props.selectedSegment?.description || ""}
                            onChange={(e) => handleInputChange('description', e.target.value)}
                        />
                    </FormControl>
                </Stack>

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }} alignItems="flex-end">
                    {props.selectedSegment?.states.editPicture && <FormControl id="photo" flexDirection="row" alignItems="center">
                        <FormLabel className="input-label">Photo</FormLabel>
                        <Flex flexDirection="row" alignItems="center" gap="4px">
                            <Tooltip
                                hasArrow
                                placement='left'
                                gutter={8}
                                label={
                                    <Box maxW="400px" maxH="400px" overflow="hidden">
                                        <Image
                                            src={props.editSegment?.picture || props.selectedSegment?.picture || ''}
                                            alt="Current photo"
                                            objectFit="contain"
                                            width="100%"
                                            height="100%"
                                        />
                                    </Box>
                                }
                                isDisabled={!props.editSegment?.picture && !props.selectedSegment?.picture}>
                                <Avatar src={props.editSegment?.picture || props.selectedSegment?.picture || ''} size="sm" />
                            </Tooltip>
                            <Input
                                type="file"
                                onChange={handleFileUpload}
                                accept="image/*"
                                isDisabled={false}
                                display="none"
                                id="file-upload-input"
                                size="sm"
                            />
                            <Button
                                as="label"
                                htmlFor="file-upload-input"
                                variant="outline"
                                borderColor="teal"
                                color="teal"
                                leftIcon={<ExternalLinkIcon color="teal" />}
                                isDisabled={false}
                                cursor="pointer"
                                size="sm"
                            >
                                Choose Picture
                            </Button>
                        </Flex>
                    </FormControl>}
                    {props.selectedSegment?.states.editAudio && <Flex flexDirection="column" alignItems="flex-start">
                        <FormLabel className="input-label">Speaker Audio</FormLabel>
                        <SpeakerAudio />
                    </Flex>}
                </Stack>
                <Divider />
                <Flex direction="row" gap="4">
                    <ProgressButton
                        leftIcon={<Icon as={FiRefreshCw} boxSize={4} />
                        }
                        colorScheme="teal"
                        variant="outline"
                        isDisabled={!props.editSegment}
                        onClick={async () => {
                            await handleUpdateSegment(props.editSegment);
                        }}
                        resetTrigger={props.selectedSegment}
                        initialText="Update"
                        runningText="Processing"
                        doneText="Completed"
                        size="sm"
                    />
                    <ProgressButton
                        leftIcon={<DeleteIcon />}
                        colorScheme="red"
                        variant="outline"
                        onClick={async () => {
                            await handleDeleteSegment(props.selectedSegment.id);
                        }}
                        resetTrigger={props.selectedSegment}
                        initialText="Delete"
                        runningText="Deleting"
                        doneText="Completed"
                        size="sm"
                    />

                    {props.editSegment && JSON.stringify(props.editSegment) !== JSON.stringify(props.selectedSegment) && <Button
                        as="label"
                        variant="outline"
                        leftIcon={<FiRotateCw />}
                        isDisabled={false}
                        cursor="pointer"
                        onClick={() => handleResetInput()}
                        size="sm"
                        colorScheme="orange"
                    >
                        Reset
                    </Button>}
                </Flex>
                <Flex direction="row" gap="4">
                    {props.selectedSegment?.states.addSegment && <ProgressButton
                        leftIcon={<Icon as={FiPlusCircle} boxSize={4} />}
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                        onClick={async () => {
                            await handleAddSegment(props.selectedSegment);
                        }}
                        resetTrigger={props.selectedSegment}
                        initialText="Add Segment"
                        runningText="Processing"
                        doneText="Completed"
                    />
                    }
                    {props.selectedSegment?.states.addSpeakertext && <ProgressButton
                        leftIcon={<Icon as={FiPlusCircle} boxSize={4} />}
                        colorScheme="teal"
                        variant="outline"
                        onClick={async () => {
                            await handleSpeakerText(props.selectedSegment);
                        }}
                        resetTrigger={props.selectedSegment}
                        initialText="Speaker Text"
                        runningText="Processing"
                        doneText="Completed"
                        size="sm"
                    />
                    }
                </Flex>
                {alertMessage && (
                    <Alert status={alertType} variant='subtle' borderRadius="5">
                        <AlertIcon />
                        {alertMessage}
                    </Alert>
                )}
            </Stack>
        </Box >
    );
}

const mapStateToProps = (state: any) => ({
    selectedSegment: state.selectedSegment.currentSegment,
    editSegment: state.selectedSegment.editSegment,
    language: state.videoForm.language,
    gender: state.videoForm.gender
});

export default connect(mapStateToProps)(SegmentsForm);
