import React, { useState, useEffect } from 'react';
import { Stack, Collapse, Box, Spinner } from '@chakra-ui/react';
import { FiGrid, FiHelpCircle, FiChevronDown, FiChevronRight, FiVideo, FiSettings } from 'react-icons/fi';
import { SidebarButton } from './SidebarButton.tsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../../store/store';
import WorkflowLibraryService from "../../../services/WorkflowLibraryService";
import { IoPersonCircleOutline } from 'react-icons/io5';
import { setActivelibrary, setWorkflowlibrary, setWorkflows } from '../../../store/workflowlibrarySlice';
import { Api, baseApiParams } from "my-api-client-package";
import { setActiveMediathekWorkflowLibaray } from '../../../store/activeMenuSlice.ts';
import { Tooltip } from '@chakra-ui/react'
export const Navigation = () => {
    const { t } = useTranslation();
    const activePage = useSelector((state: any) => state.activeMenu.activeMediathekPage);
    const [openMenus, setOpenMenus] = useState<{ [key: string]: boolean }>({});
    const workflows = useSelector((state: any) => state.workflowlibrary.workflows);
    const [isLoading, setIsLoading] = useState(false);

    const activeWorkflow = useSelector((state: any) => state.workflowlibrary.activeLibrary !== null && state.workflowlibrary.activeLibrary.label);
    const dispatch = useDispatch();
    const api = new Api({
        baseUrl: process.env.REACT_APP_API_URL
    });

    useEffect(() => {
        const currentworkflows = store.getState().workflowlibrary.workflows;
        console.log("currentworkflows", !currentworkflows || currentworkflows.length === 0);
        if (!currentworkflows || currentworkflows.length === 0) {
            setIsLoading(true);
            api.workflowlibrary.getWorkflowlibraries(baseApiParams()).then((navigationItems) => {
                const updatedNavigationItems = navigationItems.data.map((item) => ({
                    ...item,
                    loaded: false,
                }));
                dispatch(setWorkflowlibrary(updatedNavigationItems));
                setIsLoading(false);
            });
        }
    }, []);

    const getWorkflows = async () => {
        const acItem = new WorkflowLibraryService(store.getState().workflowlibrary.activeLibrary);

        if (acItem.getWorkflows.length === 0) {
            const data = await api.workflowlibrary.getWorkflowlibraryWorkflows(acItem.getWorkflowLibraryID, baseApiParams())
            store.dispatch(setWorkflows({ "workflows": data.data, "acItem": acItem.getWorkflowLibraryID }))
        }

    }

    // Define the navigation structure with submenus
    const buttons = [
        {
            label: 'Mediathek',
            page: 'Mediathek',
            icon: FiGrid,
            submenu: [
                { label: 'All Media', page: 'AllMedia' },
                { label: 'Courses', page: 'Courses' },
                { label: 'Instructions', page: 'Instructions' },
            ],
        },
        { label: 'SmartVideo', page: 'SmartVideo', icon: FiVideo },
        { label: 'Admin', page: 'Admin', icon: FiSettings },
    ];

    const isActive = (page: string) => activePage === page;

    const toggleSubmenu = (page: string) => {
        setOpenMenus((prev) => ({
            ...prev,
            [page]: !prev[page],
        }));
    };

    return (
        <Stack spacing="1" paddingTop={{ base: "16px", md: "32px" }}>
            {buttons.map(({ label, page, icon: Icon, submenu }) => (
                <Box key={page}>
                    <SidebarButton
                        onClick={() => {
                            if (submenu) {
                                toggleSubmenu(page);
                            } else {
                                store.dispatch({ type: 'mediathek/setActiveMediathekPage', payload: page });
                            }
                        }}
                        leftIcon={
                            <Icon
                                size="24"
                                color={isActive(page) ? '#E6FFFA' : '#718096'}
                                style={{ transition: 'color 0.2s' }}
                            />
                        }
                        width={"260px"}
                        bg={isActive(page) ? '#2C7A7B' : 'transparent'}
                        color={isActive(page) ? '#fff' : '#4A5568'}
                        _hover={{
                            bg: '#2C7A7B',
                            color: '#fff',
                            '& svg': { color: '#E6FFFA !important' },
                        }}
                    >
                        {label}
                        {submenu ? <Box marginLeft="auto"> {(openMenus[page] ? <FiChevronDown /> : <FiChevronRight />)}</Box> : undefined}
                    </SidebarButton>

                    {/* Submenu Items */}
                    {submenu && (
                        <Collapse in={openMenus[page]} animateOpacity>
                            <Stack px="8" spacing="1" paddingTop="1">
                                {isLoading ? (
                                    <Box display="flex" justifyContent="center" py={2}>
                                        <Spinner size="sm" color="teal.500" />
                                    </Box>
                                ) : workflows.length > 0 ? (
                                    workflows.map((workflow: any) => (
                                        <SidebarButton
                                            key={page}
                                            onClick={() => {
                                                store.dispatch(setActivelibrary(workflow))
                                                getWorkflows();
                                                store.dispatch({ type: 'mediathek/setActiveMediathekPage', payload: page })
                                            }}
                                            bg={activeWorkflow === workflow.label ? '#E6FFFA' : 'transparent'}
                                            color={activeWorkflow === workflow.label ? '#2C7A7B' : '#4A5568'}
                                            _hover={{
                                                bg: '#E6FFFA',
                                                color: '#2C7A7B',
                                            }}
                                        >
                                            {workflow.label.length > 16 ? (
                                                <Tooltip hasArrow 
                                                    label={workflow.label} 
                                                    bg='gray.300' 
                                                    color='black' 
                                                    openDelay={500} closeDelay={500}
                                                >
                                                    <span style={{textOverflow: "ellipsis", overflow:"hidden"}}>
                                                        {workflow.label}
                                                    </span>
                                                </Tooltip>
                                            ) : (
                                                <span style={{textOverflow: "ellipsis", overflow:"hidden"}}>
                                                    {workflow.label}
                                                </span>
                                            )}
                                        </SidebarButton>
                                    ))
                                ) : (
                                    <Box py={2} color="gray.500" textAlign="center">
                                        No workflows available
                                    </Box>
                                )}
                            </Stack>
                        </Collapse>
                    )}
                </Box>
            ))}
        </Stack>
    );
};
