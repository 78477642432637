import React, { useEffect, useState } from "react"
import { Card, Text, Spinner } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import WorkflowLibraryItemList from "../../WorkflowLibrary/WorkflowLibraryItemList";
import { MiscFunctions } from 'helper-functions-package';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => ({
    workflows: state.workflowlibrary.workflows,
    activeLibrary: state.workflowlibrary.activeLibrary
})

const Mediathek = (props: any) => {
    const { t } = useTranslation();
    const [workflowList, setWorkflowList] = useState([]);


    useEffect(() => {
    }, [props.activeLibrary])

    return (
        <>
            <Text className="page-heading">Mediathek</Text>
            <Card width="inherit" padding={"20px 15px 20px 15px"} boxShadow={"none"} height="calc(100vh - 120px)">
                <Text className="help-small-text" padding={"0px 0px 15px 0px"}>{props.activeLibrary.label}</Text>
                <div className="workflow-library-all-content-section">
                    {MiscFunctions.isNull(props.activeLibrary.workflows) && (
                        <Spinner
                            style={{ height: "50px", marginRight: "5px" }}
                            color="success"
                            aria-label="loading items"
                            size="xl"
                        />
                    )}
                    {!MiscFunctions.isNull(props.activeLibrary.workflows) && props.activeLibrary.workflows.length !== 0 && props.activeLibrary.workflows.map((item: any, i: any) => {
                        return (
                            <WorkflowLibraryItemList
                                workflowList={workflowList}
                                element={item}
                                key={i}
                                onClick={() => { }}
                            />
                        );
                    })}
                </div>
            </Card>
        </>
    )
}

export default connect(mapStateToProps)(Mediathek);